import { FC, lazy, Suspense, useCallback, useEffect, useMemo, useState } from 'react';
import { ChangeMode, StatsBlock } from '../../components/client-dashboard/StatsBlock';
import PageLoader from '../../components/shared/page-loader/PageLoader';
import { Trans, useTranslation } from 'react-i18next';
import ActivityFeed from '../../components/activity-feed/ActivityFeed';
import { AuditFeedItem } from '../../models/AuditFeed';
import ActivityFeedService from '../../services/ActivityFeedService';
import { useParams } from 'react-router-dom';
import ClientFormService from '../../services/ClientFormService';
import { ApiResponse } from '../../models/ApiResponse';
import DashboardBlock from '../../components/client-dashboard/DashboardBlock';
import { FormListItem } from '../../models/Form';
import DateUtils from '../../utils/DateUtils';
import TaskCompactList from '../../components/tasks/TaskCompactList';
import { colourRgb } from '../../utils/ColourUtils';
import ReportingService from '../../services/ReportingService';
import { ApplicationLocation } from '../../models/ApplicationLocation';
import BarChart, { BarChartType } from '../../components/shared/charts/BarChart';
import { ChartDataSet } from '../../components/shared/charts/ChartDataSet';
import { AssetChartData } from '../../models/AssetChartData';
import { ClientFormStatus, formStatusKeys } from '../../models/ClientFormStatus';
import ChartDataSourceSelector, { ChartDataSourceType } from '../../components/client-dashboard/ChartDataSourceSelector';
import TimeAgo from '../../components/shared/TimeAgo';
import User from '../../models/User';
import ClientService from '../../services/ClientService';
import DoughnutChart, { ChartSize } from '../../components/shared/charts/DoughnutChart';
import ExpandIcon from '../../components/shared/icon/ExpandIcon';
import { ClientFormUserStats } from '../../models/ClientFormUserStats';
import SortableHeading from '../../components/shared/data-grid/SortableHeading';
import ExpandedChartWrapper from '../../components/client-dashboard/ExpandedChartWrapper';
import TopNavPortal from '../../components/layout/top-menu/TopNavPortal';
import { Heading, HeadingSize } from '../../components/shared/text/Heading';
import NoDataPlaceholder from '../../components/client-dashboard/NoData';
import Tooltip from '../../components/shared/Tooltip';
import { ModalContext } from '../../contexts/ModalContext';
import StandardModal from '../../components/shared/modal/variants/StandardModal';
import { FormType } from '../../models/FormTypes';
import DropdownSelect from '../../components/shared/form-control/DropdownSelect';
const WorldMap = lazy(() => import('../../components/client-dashboard/WorldMap'));

type DashboardAssetStats = {
  total: number;
  completed: number;
  completedPercent: number;
  open: number;
  openProgress: number;
  highRisk: number;
};

const defaultChartColours = [
  colourRgb('dashboard-1', 0.5),
  colourRgb('dashboard-1'),
  colourRgb('dashboard-2'),
  colourRgb('dashboard-3'),
  colourRgb('primary-2'),
];

const ClientDashboard: FC = () => {
  const { t } = useTranslation(['client-dashboard', 'common', 'module-list']);
  const params = useParams<{ clientId: string }>();
  const [loadingPage] = useState(false);
  const [dueTasks, setDueTasks] = useState<ApiResponse<FormListItem[]> | null>(null);
  const [currentDueTasksPage, setCurrentDueTasksPage] = useState(1);
  const [activityFeed, setActivityFeed] = useState<ApiResponse<AuditFeedItem[]>>({} as ApiResponse<AuditFeedItem[]>);
  const [applicationLocations, setApplicationLocations] = useState<ApplicationLocation[]>([]);
  const [assetStats, setAssetStats] = useState<DashboardAssetStats>({
    total: 0,
    completed: 0,
    completedPercent: 0,
    open: 0,
    openProgress: 0,
    highRisk: 0,
  });
  const [assetStatChanges, setAssetStatChanges] = useState<DashboardAssetStats>({
    total: 0,
    completed: 0,
    completedPercent: 0,
    open: 0,
    openProgress: 0,
    highRisk: 0,
  });
  const [lastUpdated, setLastUpdated] = useState<Date | null>(null);
  const [clientUsers, setClientUsers] = useState<User[]>([]);
  const [openTasksData, setOpenTasksData] = useState<Record<string, ClientFormUserStats>>({});
  const [openTasksSorting, setOpenTasksSorting] = useState('-name');

  const dashboardFilterOptions = useMemo(
    () =>
      [
        {
          id: 'activities' as const,
          text: 'dashboard-filter.activities' as const,
          value: [FormType.Asset, FormType.Default, FormType.Startup, FormType.SubForm, FormType.SubFormWithApproval],
        },
        {
          id: 'documents' as const,
          text: 'dashboard-filter.documents' as const,
          value: [FormType.Document],
        },
      ].map((x) => ({ ...x, text: t(x.text) })),
    [t],
  );
  const [dashboardFilterSelection, setDashboardFilterSelection] = useState(dashboardFilterOptions[0]);
  const tPrefix = useMemo(() => (dashboardFilterSelection.id === 'activities' ? 'asset' : 'document'), [dashboardFilterSelection.id]);

  useEffect(() => {
    ReportingService.getLastRunTimestamp().then((res) => {
      setLastUpdated(new Date(res.data));
    });
  }, [t]);

  const getFeed = useCallback(
    (pageNumber: number) => {
      ActivityFeedService.getActivityFeed({ clientId: params.clientId, pageNumber: pageNumber, pageSize: 5 }).then((res) => {
        setActivityFeed((prev) => {
          const feedPaged = { ...res };
          if (prev.data && pageNumber > 1) {
            feedPaged.data = [...prev.data, ...res.data];
          }
          return feedPaged;
        });
      });
    },
    [params.clientId],
  );

  useEffect(() => {
    ClientFormService.getFormStats(params.clientId!, dashboardFilterSelection.value).then((res) => {
      setOpenTasksData(res.data);
    });
  }, [dashboardFilterSelection.value, params.clientId]);

  const openTasks = useMemo(() => {
    return Object.entries(openTasksData)
      .map(([userId, stats]) => {
        const user = clientUsers.find((x) => x.id === userId);
        return user
          ? { user: user.firstName && user.lastName ? `${user?.firstName} ${user?.lastName}` : user.email ?? '', total: stats.total }
          : null;
      })
      .filter((x) => !!x && x.user)
      .sort((a, b) => {
        if (!a || !b) {
          return 0;
        }

        if (openTasksSorting.endsWith('name')) {
          return a.user.localeCompare(b.user) * (openTasksSorting.startsWith('-') ? 1 : -1);
        } else {
          return (a.total < b.total ? 1 : -1) * (openTasksSorting.startsWith('-') ? 1 : -1);
        }
      });
  }, [clientUsers, openTasksData, openTasksSorting]);

  useEffect(() => {
    ReportingService.getApplicationLocations(dashboardFilterSelection.value).then((res) => {
      setApplicationLocations(res.data);
    });
    getFeed(1);
  }, [dashboardFilterSelection.value, getFeed, params.clientId]);

  useEffect(() => {
    ClientService.getUsers().then((res) => {
      setClientUsers(res);
    });
  }, [params.clientId]);

  useEffect(() => {
    ClientFormService.getFormsPaged(params.clientId as string, {
      pageSize: 10,
      pageNumber: currentDueTasksPage,
      dueDateRange: { start: DateUtils.todayStart, end: DateUtils.addDays(7, DateUtils.todayEnd) },
    }).then((res) => {
      setDueTasks((prev) => {
        const tasksPaged = { ...res };
        if (prev?.data && currentDueTasksPage > 1) {
          tasksPaged.data = [...prev.data, ...res.data];
        }
        return tasksPaged;
      });
    });
  }, [currentDueTasksPage, params.clientId]);

  const [totalAssetsChartData, setTotalAssetsChartData] = useState<ChartDataSet | null>(null);
  const [expandTotalAssetsChart, setExpandTotalAssetsChart] = useState(false);
  const [totalAssetsSelectedSource, setTotalAssetsSelectedSource] = useState<ChartDataSourceType | string>(ChartDataSourceType.AssetType);

  const [assetProgressChartData, setAssetProgressChartData] = useState<ChartDataSet | null>(null);
  const [expandAssetProgressChart, setExpandAssetProgressChart] = useState(false);
  const [assetProgressSelectedSource, setAssetProgressSelectedSource] = useState<ChartDataSourceType | string>(ChartDataSourceType.AssetType);

  const [assetRiskChartData, setAssetRiskChartData] = useState<ChartDataSet | null>(null);
  const [expandAssetRiskChart, setExpandAssetRiskChart] = useState(false);
  const [assetRiskSelectedSource, setAssetRiskSelectedSource] = useState<ChartDataSourceType | string>(ChartDataSourceType.AssetType);

  const [assetRiskOverTimeChartData, setAssetRiskOverTimeChartData] = useState<ChartDataSet | null>(null);
  const [expandRiskOverTimeChart, setExpandRiskOverTimeChart] = useState(false);

  const [assetsStatusGrupedChartData, setAssetsStatusGroupedChartData] = useState<ChartDataSet | null>(null);
  const [expandAssetsStatusGroupedChart, setExpandAssetsStatusGroupedChart] = useState(false);
  const [assetStatusGroupedSelectedSource, setAssetStatusGroupedSelectedSource] = useState<ChartDataSourceType | string>(
    ChartDataSourceType.AssetType,
  );

  const [assetsStatusChartData, setAssetsStatusChartData] = useState<ChartDataSet | null>(null);

  const sumStats = (data: AssetChartData[], statExpr: (stat: AssetChartData) => number) => {
    return data.map(statExpr).reduce((total, current) => total + current, 0);
  };

  const updateTotalAssetChart = useCallback(
    (data: AssetChartData[], heading: string, sourceId: ChartDataSourceType | string) => {
      setTotalAssetsSelectedSource(sourceId);
      setTotalAssetsChartData({
        heading: t(`client-dashboard:charts.total-assets-chart.${tPrefix}-heading`, { name: heading }),
        labels: data.map((chartData) => chartData.name),
        xLabel: t(`client-dashboard:charts.total-assets-chart.${tPrefix}-x-label`),
        hasData: data.length > 0,
        datasets: [
          {
            label: t(`client-dashboard:charts.total-assets-chart.${tPrefix}-legend.total`),
            backgroundColor: colourRgb('primary-2'),
            data: data.map((chartData) => chartData.total),
          },
        ],
      });
    },
    [t, tPrefix],
  );

  const updateRiskAssetChart = useCallback(
    (data: AssetChartData[], heading: string, sourceId: ChartDataSourceType | string) => {
      setAssetRiskSelectedSource(sourceId);
      setAssetRiskChartData({
        heading: t(`client-dashboard:charts.assets-risk-chart.${tPrefix}-heading`, { name: heading }),
        labels: data.map((chartData) => chartData.name),
        xLabel: t(`client-dashboard:charts.assets-risk-chart.${tPrefix}-x-label`),
        hasData: data.length > 0,
        datasets: [
          {
            label: t('client-dashboard:charts.assets-risk-chart.legend.low_risk'),
            backgroundColor: defaultChartColours[1],
            data: data.map((chartData) => chartData.lowRisk),
          },
          {
            label: t('client-dashboard:charts.assets-risk-chart.legend.medium_risk'),
            backgroundColor: defaultChartColours[2],
            data: data.map((chartData) => chartData.mediumRisk),
          },
          {
            label: t('client-dashboard:charts.assets-risk-chart.legend.high_risk'),
            backgroundColor: defaultChartColours[3],
            data: data.map((chartData) => chartData.highRisk),
          },
        ],
      });
    },
    [t, tPrefix],
  );

  const updateAssetProgressChart = useCallback(
    (data: AssetChartData[], heading: string, sourceId: ChartDataSourceType | string) => {
      setAssetProgressSelectedSource(sourceId);
      setAssetProgressChartData({
        heading: t(`client-dashboard:charts.open-assets-chart.${tPrefix}-heading`, { name: heading }),
        labels: data.map((chartData) => chartData.name),
        xLabel: t('client-dashboard:charts.assets-chart.x-label'),
        hasData: data.length > 0,
        datasets: [
          {
            label: t('client-dashboard:charts.assets-chart.legend.progress'),
            backgroundColor: colourRgb('dashboard-1'),
            data: data.map((chartData) => Math.round(chartData.openProgress * 100)),
          },
        ],
      });
    },
    [t, tPrefix],
  );

  const updateAssetStatusGroupChart = useCallback(
    (data: AssetChartData[], heading: string, sourceId: ChartDataSourceType | string) => {
      setAssetStatusGroupedSelectedSource(sourceId);
      setAssetsStatusGroupedChartData({
        heading: t(`client-dashboard:charts.assets-status-group-chart.${tPrefix}-heading`, { name: heading }),
        labels: data.map((chartData) => chartData.name),
        xLabel: t(`client-dashboard:charts.assets-status-group-chart.${tPrefix}-x-label`),
        hasData: data.length > 0,
        datasets: [
          {
            label: t(formStatusKeys[ClientFormStatus.NotStarted]),
            backgroundColor: defaultChartColours[0],
            data: data.map((chartData) => chartData.notStarted),
          },
          {
            label: t(formStatusKeys[ClientFormStatus.InProgress]),
            backgroundColor: defaultChartColours[1],
            data: data.map((chartData) => chartData.inProgress),
          },
          {
            label: t(formStatusKeys[ClientFormStatus.SubmittedForValidation]),
            backgroundColor: defaultChartColours[2],
            data: data.map((chartData) => chartData.submitted),
          },
          {
            label: t(formStatusKeys[ClientFormStatus.SubmittedForApproval]),
            backgroundColor: defaultChartColours[3],
            data: data.map((chartData) => chartData.validated),
          },
          {
            label: t(formStatusKeys[ClientFormStatus.Completed]),
            backgroundColor: defaultChartColours[4],
            data: data.map((chartData) => chartData.completed),
          },
        ],
      });
    },
    [t, tPrefix],
  );

  useEffect(() => {
    ReportingService.getAssetChartData(DateUtils.now, dashboardFilterSelection.value).then((res) => {
      const totalAssets = sumStats(res.data, (stat) => stat.total);
      const totalComplete = sumStats(res.data, (stat) => stat.completed);
      const completePerc = totalAssets === 0 ? 0 : Math.round((totalComplete / totalAssets) * 100);
      const openAssets = totalAssets - totalComplete;
      const openProgress = res.data.length === 0 ? 0 : Math.round((sumStats(res.data, (stat) => stat.openProgress) / res.data.length) * 100);
      const highRisk = sumStats(res.data, (stat) => stat.highRisk);
      setAssetStats({
        total: totalAssets,
        completed: totalComplete,
        completedPercent: completePerc,
        open: openAssets,
        openProgress: openProgress,
        highRisk: highRisk,
      });

      ReportingService.getAssetChartData(DateUtils.addDays(-7), dashboardFilterSelection.value).then((res) => {
        const oldTotalAssets = sumStats(res.data, (stat) => stat.total);
        const oldTotalComplete = sumStats(res.data, (stat) => stat.completed);
        const oldCompletePerc = totalAssets === 0 ? 0 : Math.round((totalComplete / totalAssets) * 100);
        const oldOpenAssets = totalAssets - oldTotalComplete;
        const oldOpenProgress = res.data.length === 0 ? 0 : Math.round((sumStats(res.data, (stat) => stat.openProgress) / res.data.length) * 100);
        const oldHighRisk = sumStats(res.data, (stat) => stat.highRisk);
        setAssetStatChanges({
          total: totalAssets - oldTotalAssets,
          completed: totalComplete - oldTotalComplete,
          completedPercent: completePerc - oldCompletePerc,
          open: openAssets - oldOpenAssets,
          openProgress: openProgress - oldOpenProgress,
          highRisk: highRisk - oldHighRisk,
        });
      });

      updateTotalAssetChart(res.data, t(`client-dashboard:charts.data-source.static.${tPrefix}-type`), ChartDataSourceType.AssetType);
      updateRiskAssetChart(res.data, t(`client-dashboard:charts.data-source.static.${tPrefix}-type`), ChartDataSourceType.AssetType);
      updateAssetProgressChart(res.data, t(`client-dashboard:charts.data-source.static.${tPrefix}-type`), ChartDataSourceType.AssetType);
      updateAssetStatusGroupChart(res.data, t(`client-dashboard:charts.data-source.static.${tPrefix}-type`), ChartDataSourceType.AssetType);

      setAssetsStatusChartData({
        heading: t(`client-dashboard:charts.assets-status-chart.${tPrefix}-heading`),
        labels: [
          t(formStatusKeys[ClientFormStatus.NotStarted]),
          t(formStatusKeys[ClientFormStatus.InProgress]),
          t(formStatusKeys[ClientFormStatus.SubmittedForValidation]),
          t(formStatusKeys[ClientFormStatus.SubmittedForApproval]),
          t(formStatusKeys[ClientFormStatus.Completed]),
        ],
        xLabel: t(`client-dashboard:charts.assets-status-chart.${tPrefix}-x-label`),
        hasData: res.data.length > 0,
        datasets: [
          {
            label: t('client-dashboard:charts.assets-status-chart.legend.in-progress'),
            backgroundColor: colourRgb('dashboard-1'),
            data: [
              sumStats(res.data, (stat) => stat.notStarted),
              sumStats(res.data, (stat) => stat.inProgress),
              sumStats(res.data, (stat) => stat.submitted),
              sumStats(res.data, (stat) => stat.validated),
              sumStats(res.data, (stat) => stat.completed),
            ],
          },
        ],
      });
    });
  }, [
    dashboardFilterSelection.value,
    params.clientId,
    t,
    tPrefix,
    updateAssetProgressChart,
    updateAssetStatusGroupChart,
    updateRiskAssetChart,
    updateTotalAssetChart,
  ]);

  useEffect(() => {
    ReportingService.getAssetsRiskOverTimeChartData(dashboardFilterSelection.value).then((res) => {
      const labels = res.data.map((chartData) => `${DateUtils.getMonthTranslation(t, chartData.month, false)} ${chartData.year}`);
      setAssetRiskOverTimeChartData({
        heading: t(`client-dashboard:charts.assets-risk-over-time-chart.${tPrefix}-heading`),
        labels: labels,
        xLabel: t(`client-dashboard:charts.total-assets-chart.${tPrefix}-x-label`),
        hasData: res.data.map((chartData) => chartData.total).reduce((total, current) => total + current, 0) > 0,
        datasets: [
          {
            label: t('client-dashboard:charts.assets-risk-over-time-chart.legend.low_risk'),
            backgroundColor: colourRgb('dashboard-1'),
            data: res.data.map((chartData) => chartData.lowRisk),
          },
          {
            label: t('client-dashboard:charts.assets-risk-over-time-chart.legend.medium_risk'),
            backgroundColor: colourRgb('dashboard-2'),
            data: res.data.map((chartData) => chartData.mediumRisk),
          },
          {
            label: t('client-dashboard:charts.assets-risk-over-time-chart.legend.high_risk'),
            backgroundColor: colourRgb('dashboard-3'),
            data: res.data.map((chartData) => chartData.highRisk),
          },
          {
            label: t(`client-dashboard:charts.assets-risk-over-time-chart.legend.${tPrefix}-total`),
            backgroundColor: colourRgb('primary-2'),
            borderColor: colourRgb('primary-2'),
            data: res.data.map((chartData) => chartData.total),
            type: 'line' as const,
            order: 2,
            fill: false,
          },
        ],
      });
    });
  }, [dashboardFilterSelection.value, params.clientId, t, tPrefix]);

  const switchDataSource = useCallback(
    (
      type: ChartDataSourceType,
      value: string,
      text: string,
      update: (data: AssetChartData[], heading: string, sourceId: ChartDataSourceType | string) => void,
    ) => {
      switch (type) {
        case ChartDataSourceType.AssetType:
          {
            ReportingService.getAssetChartData(DateUtils.now, dashboardFilterSelection.value).then((res) => {
              update(res.data, text, ChartDataSourceType.AssetType);
            });
          }
          break;
        case ChartDataSourceType.AssociatedClients:
          {
            ReportingService.getAssetChartDataForAssociatedClients(dashboardFilterSelection.value).then((res) => {
              update(res.data, text, ChartDataSourceType.AssociatedClients);
            });
          }
          break;
        case ChartDataSourceType.Modules:
          {
            ReportingService.getAssetChartDataForModules(dashboardFilterSelection.value).then((res) => {
              update(res.data, text, ChartDataSourceType.Modules);
            });
          }
          break;
        case ChartDataSourceType.Users:
          {
            ReportingService.getAssetChartDataForUsers(dashboardFilterSelection.value).then((res) => {
              update(
                res.data
                  .map((x) => {
                    const clientUser = clientUsers.find((u) => u.id === x.name);
                    return {
                      ...x,
                      name:
                        clientUser?.firstName && clientUser?.lastName ? `${clientUser?.firstName} ${clientUser?.lastName}` : clientUser?.email ?? '',
                    };
                  })
                  .filter((x) => x.name),
                text,
                ChartDataSourceType.Users,
              );
            });
          }
          break;
        case ChartDataSourceType.PickList:
          {
            ReportingService.getAssetChartDataForPickList(value, dashboardFilterSelection.value).then((res) => {
              update(res.data, text, value);
            });
          }
          break;
      }
    },
    [clientUsers, dashboardFilterSelection.value],
  );

  const modalHeight = 'h-[calc(100vh-10rem)]';

  return (
    <div className="bg-background-1 relative flex min-h-full w-full flex-col p-6">
      <TopNavPortal>
        <div className="flex items-center gap-3">
          <Heading size={HeadingSize.H2} actualSize={HeadingSize.H3}>
            {t('client-dashboard:heading')}
          </Heading>
          <div className="max-w-[300px]">
            <DropdownSelect
              options={dashboardFilterOptions}
              onChange={setDashboardFilterSelection}
              value={dashboardFilterSelection}
              aria-label={t('common:aria-label.dashboard-filter-select')}
            />
          </div>
        </div>
      </TopNavPortal>
      <PageLoader loading={loadingPage}>
        <div className="text-dpm-14 -mt-4 flex justify-end">
          <Trans t={t} i18nKey="client-dashboard:last-updated" components={{ TimeAgo: <TimeAgo date={lastUpdated} /> }} />
        </div>
        <div className="grid w-full grid-cols-6 gap-2" data-cy="stats-grid">
          <div className="col-span-1 row-span-1">
            <StatsBlock
              translationNamespace="client-dashboard"
              headingKey={`client-dashboard:stats.${tPrefix}-total`}
              stat={assetStats.total}
              change={assetStatChanges.total}
            />
          </div>
          <div className="col-span-1 row-span-1">
            <StatsBlock
              translationNamespace="client-dashboard"
              headingKey={`client-dashboard:stats.${tPrefix}-completed-total`}
              stat={assetStats.completed}
              change={assetStatChanges.completed}
            />
          </div>
          <div className="col-span-1 row-span-1">
            <StatsBlock
              translationNamespace="client-dashboard"
              headingKey={`client-dashboard:stats.${tPrefix}-completed-percent`}
              statsKey="client-dashboard:stats.percentage-symbol"
              stat={assetStats.completedPercent}
              change={assetStatChanges.completedPercent}
            ></StatsBlock>
          </div>
          <div className="col-span-1 row-span-1">
            <StatsBlock
              translationNamespace="client-dashboard"
              headingKey={`client-dashboard:stats.${tPrefix}-open-total`}
              stat={assetStats.open}
              change={assetStatChanges.open}
              changeMode={ChangeMode.NEUTRAL}
            />
          </div>
          <div className="col-span-1 row-span-1">
            <StatsBlock
              translationNamespace="client-dashboard"
              headingKey={`client-dashboard:stats.${tPrefix}-open-progress`}
              statsKey="client-dashboard:stats.percentage-symbol"
              stat={assetStats.openProgress}
              change={assetStatChanges.openProgress}
            />
          </div>
          <div className="col-span-1 row-span-1">
            <StatsBlock
              translationNamespace="client-dashboard"
              headingKey={`client-dashboard:stats.${tPrefix}-total-high-risk`}
              stat={assetStats.highRisk}
              change={assetStatChanges.highRisk}
              changeMode={ChangeMode.NEGATIVE_GOOD}
            />
          </div>
          <div className="h-dashboard-row-3 4k:h-dashboard-row-4 col-span-2 row-span-1">
            <DashboardBlock>
              <DashboardBlock.Header>
                <div className="relative flex justify-between">
                  <Tooltip text={totalAssetsChartData?.heading}>
                    {(tooltip) => (
                      <div className="truncate" {...tooltip}>
                        {totalAssetsChartData?.heading}
                      </div>
                    )}
                  </Tooltip>

                  <div className="flex items-center gap-2">
                    <ChartDataSourceSelector
                      mode={tPrefix}
                      onChange={(type, value, text) => switchDataSource(type, value, text, updateTotalAssetChart)}
                      selectedSource={totalAssetsSelectedSource}
                    />
                    {totalAssetsChartData && totalAssetsChartData.hasData && (
                      <ExpandIcon onClick={() => setExpandTotalAssetsChart(true)} className="text-primary-1" />
                    )}
                  </div>
                </div>
              </DashboardBlock.Header>
              <DashboardBlock.Body>
                {totalAssetsChartData && totalAssetsChartData.hasData && (
                  <BarChart
                    filterZeros={typeof totalAssetsSelectedSource !== 'number'}
                    data={totalAssetsChartData}
                    limit={8}
                    stacked={true}
                    type={BarChartType.HORIZONTAL}
                  />
                )}
                {totalAssetsChartData && !totalAssetsChartData.hasData && (
                  <NoDataPlaceholder>{t(`client-dashboard:charts.assets-chart.${tPrefix}-no-data`)}</NoDataPlaceholder>
                )}
              </DashboardBlock.Body>
            </DashboardBlock>
            {totalAssetsChartData && (
              <ModalContext.Provider
                value={{ open: expandTotalAssetsChart, className: modalHeight, onClose: () => setExpandTotalAssetsChart(false) }}
              >
                <StandardModal
                  title={
                    <div className="relative flex w-full justify-between">
                      {totalAssetsChartData?.heading}
                      <div className="relative mr-2">
                        <ChartDataSourceSelector
                          mode={tPrefix}
                          onChange={(type, value, text) => switchDataSource(type, value, text, updateTotalAssetChart)}
                          selectedSource={totalAssetsSelectedSource}
                        />
                      </div>
                    </div>
                  }
                >
                  {totalAssetsChartData && (
                    <ExpandedChartWrapper labelCount={totalAssetsChartData?.labels.length}>
                      <BarChart
                        key={`totalAssetsChartData_${totalAssetsChartData.labels.length}`}
                        data={totalAssetsChartData}
                        stacked={true}
                        type={BarChartType.HORIZONTAL}
                      />
                    </ExpandedChartWrapper>
                  )}
                </StandardModal>
              </ModalContext.Provider>
            )}
          </div>
          <div className="h-dashboard-row-3 4k:h-dashboard-row-4 col-span-2 row-span-1">
            <DashboardBlock>
              <DashboardBlock.Header>
                <Tooltip text={assetsStatusChartData?.heading}>
                  {(tooltip) => (
                    <div className="truncate" {...tooltip}>
                      {assetsStatusChartData?.heading}
                    </div>
                  )}
                </Tooltip>
              </DashboardBlock.Header>
              <DashboardBlock.Body>
                {assetsStatusChartData && assetsStatusChartData.hasData && (
                  <DoughnutChart
                    dataset={assetsStatusChartData.datasets[0].data}
                    dataLabels={assetsStatusChartData.labels}
                    disabled={assetStats.total === 0}
                  >
                    <div className="text-dpm-48">{assetStats.total}</div>
                  </DoughnutChart>
                )}
                {assetsStatusChartData && !assetsStatusChartData.hasData && (
                  <NoDataPlaceholder>{t(`client-dashboard:charts.assets-chart.${tPrefix}-no-data`)}</NoDataPlaceholder>
                )}
              </DashboardBlock.Body>
            </DashboardBlock>
          </div>
          <div className="h-dashboard-row-3 4k:h-dashboard-row-4 col-span-2 row-span-1">
            <DashboardBlock>
              <DashboardBlock.Header>
                <div className="relative flex items-center justify-between">
                  <Tooltip text={assetRiskChartData?.heading}>
                    {(tooltip) => (
                      <div className="truncate" {...tooltip}>
                        {assetRiskChartData?.heading}
                      </div>
                    )}
                  </Tooltip>
                  <div className="flex items-center gap-2">
                    <ChartDataSourceSelector
                      mode={tPrefix}
                      onChange={(type, value, text) => switchDataSource(type, value, text, updateRiskAssetChart)}
                      selectedSource={assetRiskSelectedSource}
                    />
                    {assetRiskChartData && assetRiskChartData.hasData && (
                      <ExpandIcon onClick={() => setExpandAssetRiskChart(true)} className="text-primary-1" />
                    )}
                  </div>
                </div>
              </DashboardBlock.Header>
              <DashboardBlock.Body>
                {assetRiskChartData && assetRiskChartData.hasData && (
                  <BarChart
                    filterZeros={typeof assetRiskSelectedSource !== 'number'}
                    data={assetRiskChartData}
                    limit={8}
                    stacked={true}
                    type={BarChartType.HORIZONTAL}
                  />
                )}
                {assetRiskChartData && !assetRiskChartData.hasData && (
                  <NoDataPlaceholder>{t('client-dashboard:charts.assets-risk-chart.no-data')}</NoDataPlaceholder>
                )}
              </DashboardBlock.Body>
            </DashboardBlock>
            <ModalContext.Provider value={{ open: expandAssetRiskChart, className: modalHeight, onClose: () => setExpandAssetRiskChart(false) }}>
              <StandardModal
                title={
                  <div className="relative flex w-full justify-between">
                    {assetRiskChartData?.heading}
                    <div className="relative mr-2">
                      <ChartDataSourceSelector
                        mode={tPrefix}
                        onChange={(type, value, text) => switchDataSource(type, value, text, updateRiskAssetChart)}
                        selectedSource={assetRiskSelectedSource}
                      />
                    </div>
                  </div>
                }
              >
                {assetRiskChartData && (
                  <ExpandedChartWrapper labelCount={assetRiskChartData?.labels.length}>
                    <BarChart
                      key={`assetRiskChartData_${assetRiskChartData.labels.length}`}
                      data={assetRiskChartData}
                      stacked={true}
                      type={BarChartType.HORIZONTAL}
                    />
                  </ExpandedChartWrapper>
                )}
              </StandardModal>
            </ModalContext.Provider>
          </div>
          <div className="h-dashboard-row-3 4k:h-dashboard-row-4 col-span-3 row-span-1">
            <DashboardBlock>
              <DashboardBlock.Header>
                <Tooltip text={t('client-dashboard:map.heading')}>
                  {(tooltip) => (
                    <div className="truncate" {...tooltip}>
                      {t('client-dashboard:map.heading')}
                    </div>
                  )}
                </Tooltip>
              </DashboardBlock.Header>
              <DashboardBlock.Body bodyPadding={false}>
                <Suspense fallback={<PageLoader loading isSuspense />}>
                  <WorldMap data={applicationLocations} />
                </Suspense>
              </DashboardBlock.Body>
            </DashboardBlock>
          </div>
          <div className="h-dashboard-row-3 4k:h-dashboard-row-4 col-span-3 row-span-1">
            <DashboardBlock>
              <DashboardBlock.Header>
                <div className="relative flex justify-between">
                  <Tooltip text={assetRiskOverTimeChartData?.heading}>
                    {(tooltip) => (
                      <div className="truncate" {...tooltip}>
                        {assetRiskOverTimeChartData?.heading}
                      </div>
                    )}
                  </Tooltip>
                  <div className="flex">
                    {assetRiskOverTimeChartData && assetRiskOverTimeChartData.hasData && (
                      <ExpandIcon onClick={() => setExpandRiskOverTimeChart(true)} className="text-primary-1" />
                    )}
                  </div>
                </div>
              </DashboardBlock.Header>
              <DashboardBlock.Body>
                {assetRiskOverTimeChartData && assetRiskOverTimeChartData.hasData && <BarChart data={assetRiskOverTimeChartData} limit={-6} />}{' '}
                {assetRiskOverTimeChartData && !assetRiskOverTimeChartData.hasData && (
                  <NoDataPlaceholder>{t('client-dashboard:charts.assets-risk-chart.no-data')}</NoDataPlaceholder>
                )}
              </DashboardBlock.Body>
            </DashboardBlock>
            <ModalContext.Provider
              value={{ open: expandRiskOverTimeChart, className: modalHeight, onClose: () => setExpandRiskOverTimeChart(false) }}
            >
              <StandardModal title={assetRiskOverTimeChartData?.heading}>
                {assetRiskOverTimeChartData && <BarChart data={assetRiskOverTimeChartData} />}
              </StandardModal>
            </ModalContext.Provider>
          </div>
          <div className="h-dashboard-row-3 4k:h-dashboard-row-4 col-span-2 row-span-1">
            <DashboardBlock>
              <DashboardBlock.Header>
                <div className="relative flex justify-between">
                  <Tooltip text={assetProgressChartData?.heading}>
                    {(tooltip) => (
                      <div className="truncate" {...tooltip}>
                        {assetProgressChartData?.heading}
                      </div>
                    )}
                  </Tooltip>
                  <div className="flex items-center gap-2">
                    <ChartDataSourceSelector
                      mode={tPrefix}
                      onChange={(type, value, text) => switchDataSource(type, value, text, updateAssetProgressChart)}
                      selectedSource={assetProgressSelectedSource}
                    />
                    {assetProgressChartData && assetProgressChartData.hasData && (
                      <ExpandIcon onClick={() => setExpandAssetProgressChart(true)} className="text-primary-1" />
                    )}
                  </div>
                </div>
              </DashboardBlock.Header>
              <DashboardBlock.Body>
                {assetProgressChartData && assetProgressChartData.hasData && (
                  <div className={`flex h-full max-h-full w-full flex-wrap items-start justify-center pt-6`}>
                    {assetProgressChartData.datasets[0].data.map((progress, i) => {
                      if (i <= 5) {
                        return (
                          <div className="relative h-1/4 w-1/2 xl:h-1/3 xl:w-1/3" key={`asset-progress-donut-${i}`}>
                            <DoughnutChart
                              dataset={[progress, 100 - progress]}
                              title={assetProgressChartData.labels[i]}
                              dataLabels={[]}
                              hideLabels
                              colours={[defaultChartColours[1], defaultChartColours[0]]}
                              size={ChartSize.SMALL}
                            >
                              <div className="text-dpm-20">{progress}%</div>
                            </DoughnutChart>
                          </div>
                        );
                      }
                    })}
                  </div>
                )}
                {assetProgressChartData && !assetProgressChartData.hasData && (
                  <NoDataPlaceholder>{t('client-dashboard:charts.open-assets-chart.no-data')}</NoDataPlaceholder>
                )}
              </DashboardBlock.Body>
            </DashboardBlock>
            <ModalContext.Provider
              value={{ open: expandAssetProgressChart, className: modalHeight, onClose: () => setExpandAssetProgressChart(false) }}
            >
              <StandardModal
                title={
                  <div className="relative flex w-full justify-between">
                    {assetProgressChartData?.heading}
                    <div className="relative mr-2">
                      <ChartDataSourceSelector
                        mode={tPrefix}
                        onChange={(type, value, text) => switchDataSource(type, value, text, updateAssetProgressChart)}
                        selectedSource={assetProgressSelectedSource}
                      />
                    </div>
                  </div>
                }
              >
                {assetProgressChartData && (
                  <ExpandedChartWrapper labelCount={assetProgressChartData?.labels.length}>
                    <BarChart
                      key={`assetProgressChartData_${assetProgressChartData.labels.length}`}
                      data={assetProgressChartData}
                      stacked={true}
                      type={BarChartType.HORIZONTAL}
                    />
                  </ExpandedChartWrapper>
                )}
              </StandardModal>
            </ModalContext.Provider>
          </div>
          <div className="h-dashboard-row-3 4k:h-dashboard-row-4 col-span-2 row-span-1">
            <DashboardBlock scrollOverflow>
              <DashboardBlock.Header>
                <Tooltip text={t('client-dashboard:tasks.heading')}>
                  {(tooltip) => (
                    <div className="truncate" {...tooltip}>
                      {t('client-dashboard:tasks.heading')}
                    </div>
                  )}
                </Tooltip>
              </DashboardBlock.Header>
              <DashboardBlock.Body>
                {dueTasks && (dueTasks.data.length ?? 0) > 0 && <TaskCompactList forms={dueTasks} onLoadMore={setCurrentDueTasksPage} />}
                {dueTasks?.data.length === 0 && <NoDataPlaceholder>{t('client-dashboard:tasks.nothing-due')}</NoDataPlaceholder>}
              </DashboardBlock.Body>
            </DashboardBlock>
          </div>
          <div className="h-dashboard-row-3 4k:h-dashboard-row-4 col-span-2 row-span-1">
            <DashboardBlock>
              <DashboardBlock.Header>
                <div className="relative flex justify-between">
                  <Tooltip text={assetsStatusGrupedChartData?.heading || t(`client-dashboard:charts.assets-status-group-chart.${tPrefix}-heading`)}>
                    {(tooltip) => (
                      <div className="truncate" {...tooltip}>
                        {assetsStatusGrupedChartData?.heading || t(`client-dashboard:charts.assets-status-group-chart.${tPrefix}-heading`)}
                      </div>
                    )}
                  </Tooltip>
                  <div className="flex items-center gap-2">
                    <ChartDataSourceSelector
                      mode={tPrefix}
                      onChange={(type, value, text) => switchDataSource(type, value, text, updateAssetStatusGroupChart)}
                      selectedSource={assetStatusGroupedSelectedSource}
                    />
                    {assetsStatusGrupedChartData && assetsStatusGrupedChartData.hasData && (
                      <ExpandIcon onClick={() => setExpandAssetsStatusGroupedChart(true)} className="text-primary-1" />
                    )}
                  </div>
                </div>
              </DashboardBlock.Header>
              <DashboardBlock.Body>
                {assetsStatusGrupedChartData && assetsStatusGrupedChartData.hasData && (
                  <div className="flex h-full max-h-full w-full flex-wrap items-start justify-center pt-6">
                    {assetsStatusGrupedChartData.labels.map((label, i) => {
                      if (i <= 5) {
                        const data = assetsStatusGrupedChartData.datasets.map((x) => x.data[i]);
                        const total = data.reduce((a, b) => a + b, 0);
                        return (
                          <div className="relative h-1/4 w-1/2 xl:h-1/3 xl:w-1/3" key={`asset-progress-donut-${i}`}>
                            <DoughnutChart
                              dataset={data}
                              title={label}
                              dataLabels={assetsStatusGrupedChartData.datasets.map((x) => x.label)}
                              hideLabels
                              colours={defaultChartColours}
                              disabled={total === 0}
                              size={ChartSize.SMALL}
                            >
                              <div className="text-dpm-20">{total}</div>
                            </DoughnutChart>
                          </div>
                        );
                      }
                    })}
                  </div>
                )}
                {assetsStatusGrupedChartData && !assetsStatusGrupedChartData.hasData && (
                  <NoDataPlaceholder>{t(`client-dashboard:charts.assets-status-chart.${tPrefix}-no-data`)}</NoDataPlaceholder>
                )}
              </DashboardBlock.Body>
            </DashboardBlock>
            <ModalContext.Provider
              value={{ open: expandAssetsStatusGroupedChart, className: modalHeight, onClose: () => setExpandAssetsStatusGroupedChart(false) }}
            >
              <StandardModal
                title={
                  <div className="relative flex w-full justify-between">
                    {assetsStatusGrupedChartData?.heading}
                    <div className="relative mr-2">
                      <ChartDataSourceSelector
                        mode={tPrefix}
                        onChange={(type, value, text) => switchDataSource(type, value, text, updateAssetStatusGroupChart)}
                        selectedSource={assetStatusGroupedSelectedSource}
                      />
                    </div>
                  </div>
                }
              >
                {assetsStatusGrupedChartData && (
                  <ExpandedChartWrapper labelCount={assetsStatusGrupedChartData?.labels.length}>
                    <BarChart
                      key={`assetsStatusGrupedChartData${assetsStatusGrupedChartData.labels.length}`}
                      data={assetsStatusGrupedChartData}
                      stacked={true}
                      type={BarChartType.HORIZONTAL}
                    />
                  </ExpandedChartWrapper>
                )}
              </StandardModal>
            </ModalContext.Provider>
          </div>
          <div className="h-dashboard-row-3 4k:h-dashboard-row-4 col-span-2 row-span-1">
            <DashboardBlock scrollOverflow>
              <DashboardBlock.Header>
                <Tooltip text={t('client-dashboard:open-tasks.heading')}>
                  {(tooltip) => (
                    <div className="truncate" {...tooltip}>
                      {t('client-dashboard:open-tasks.heading')}
                    </div>
                  )}
                </Tooltip>
              </DashboardBlock.Header>
              <DashboardBlock.Body>
                {openTasks.length > 0 && (
                  <>
                    <div className="text-gray-1 my-1 flex justify-between px-3 font-medium">
                      <SortableHeading
                        title={t('client-dashboard:open-tasks.name')}
                        onSort={(_, expression) => setOpenTasksSorting(expression)}
                        expression={openTasksSorting.endsWith('name') ? openTasksSorting : 'name'}
                      />
                      <SortableHeading
                        title={t('client-dashboard:open-tasks.total')}
                        onSort={(_, expression) => setOpenTasksSorting(expression)}
                        expression={openTasksSorting.endsWith('total') ? openTasksSorting : 'total'}
                      />
                    </div>
                    <div className="max-h-full overflow-y-auto px-3">
                      {openTasks.map((task, i) => (
                        <div key={i} className="my-1 flex justify-between">
                          <div>{task?.user}</div>
                          <div>{task?.total}</div>
                        </div>
                      ))}
                    </div>
                  </>
                )}
                {openTasks.length === 0 && <NoDataPlaceholder>{t('client-dashboard:open-tasks.no-data')}</NoDataPlaceholder>}
              </DashboardBlock.Body>
            </DashboardBlock>
          </div>
          <div className="h-dashboard-row-3 4k:h-dashboard-row-4 col-span-2 row-span-1">
            <DashboardBlock scrollOverflow>
              <DashboardBlock.Header>
                <Tooltip text={t('client-dashboard:activities.heading')}>
                  {(tooltip) => (
                    <div className="truncate" {...tooltip}>
                      {t('client-dashboard:activities.heading')}
                    </div>
                  )}
                </Tooltip>
              </DashboardBlock.Header>
              <DashboardBlock.Body>
                <div className="max-h-full overflow-y-auto">
                  <ActivityFeed feed={activityFeed} onLoadMore={getFeed} />
                </div>
                {activityFeed.totalCount === 0 && <NoDataPlaceholder>{t('client-dashboard:activities.no-data')}</NoDataPlaceholder>}
              </DashboardBlock.Body>
            </DashboardBlock>
          </div>
        </div>
      </PageLoader>
    </div>
  );
};

export default ClientDashboard;
